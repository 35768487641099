import React from "react";
import RolesConfig from "../../config/Roles";

function DetailHeader({
  solicitudDetalle,
  roles,
  atendidoPor,
  setShowModalReasignar,
  usuarioId,
  modalEstadoSolicitud,
  ableInputs,
  setInputsHabilitados,
  inputsHabilitados,
  btnActualizar,
  ActualizacionParcial,
  handleSubmit,
  actualizacionParcial,
  actualizar,
}) {
  const Rol = RolesConfig;

  return (
    <div className="controls-sticky">
      <div className="divAction">
        <div>
          <h2 id="codigo-solicitud">
            Solicitud no. {solicitudDetalle?.numero}:
          </h2>
          <div className="subtitulo-solicitud mt-2 mb-2">
            {solicitudDetalle?.nombres} {solicitudDetalle?.primerApellido}{" "}
            {solicitudDetalle?.segundoApellido}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 20,
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          {solicitudDetalle?.academicGStatus && (
            <i
              className="material-icons"
              style={{
                fontSize: 30,
                color: "#016AE9",
                cursor: "pointer",
              }}
              title={"Se ha graduado"}
            >
              
            </i>
          )}
          <div>
            {solicitudDetalle?.estadoId != 10 &&
            solicitudDetalle?.estadoId != 13 &&
            solicitudDetalle?.estadoId != 17 ? (
              //if dependiendo del rol

              (roles?.includes(Rol.oficialAdmisiones) &&
                solicitudDetalle?.estadoId == 9) ||
              solicitudDetalle?.estadoId == 8 ? (
                atendidoPor == null ? (
                  <>
                    {roles?.includes(Rol.DIRECTOR) && (
                      <>
                        <div className="btnGroupAction">
                          <button
                            className="btnPrimary"
                            onClick={() => {
                              setShowModalReasignar(true);
                            }}
                          >
                            Asignar
                          </button>
                        </div>
                      </>
                    )}
                  </>
                ) : usuarioId === atendidoPor || roles?.includes(Rol.ADMIN) ? (
                  <div className="btnGroupAction">
                    <button
                      className="btnPrimary"
                      onClick={() => {
                        setShowModalReasignar(true);
                      }}
                    >
                      Reasignar
                    </button>

                    {solicitudDetalle?.estadoId == 9 ? (
                      <>
                        <button
                          className="btnYellow"
                          onClick={() => {
                            modalEstadoSolicitud(true, 17);
                          }}
                        >
                          Devolver
                        </button>
                        <button
                          className="btnBlue"
                          onClick={() => {
                            modalEstadoSolicitud(true, 18);
                          }}
                        >
                          Preaprobar
                        </button>
                        <button
                          className="btnOutline"
                          onClick={() => {
                            modalEstadoSolicitud(true, 13);
                          }}
                        >
                          Rechazar
                        </button>
                        <button
                          id="solicitudEdit"
                          onClick={() => {
                            ableInputs();
                            setInputsHabilitados(!inputsHabilitados);
                          }}
                        >
                          <i className="material-icons"></i>
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <span className="subtitulo-solicitud">
                    Atendido por: {solicitudDetalle?.atendidoPorNombre}
                  </span>
                )
              ) : roles?.includes(Rol.DIRECTOR) &&
                solicitudDetalle?.estadoId == 18 ? (
                <>
                  <div className="btnGroupAction">
                    <button
                      className="btnYellow"
                      onClick={() => {
                        modalEstadoSolicitud(true, 17);
                      }}
                    >
                      Devolver
                    </button>
                    <button
                      className="btnBlue"
                      onClick={() => {
                        modalEstadoSolicitud(true, 19);
                      }}
                    >
                      Aprobar
                    </button>
                    <button
                      className="btnOutline"
                      onClick={() => {
                        modalEstadoSolicitud(true, 13);
                      }}
                    >
                      Rechazar
                    </button>
                    <button
                      id="solicitudEdit"
                      onClick={() => {
                        ableInputs();
                        setInputsHabilitados(!inputsHabilitados);
                      }}
                    >
                      <i className="material-icons"></i>
                    </button>
                  </div>
                </>
              ) : (
                roles?.includes(Rol.REGISTRO) &&
                solicitudDetalle?.estadoId == 19 && (
                  <>
                    <div className="btnGroupAction">
                      <button
                        className="btnYellow"
                        onClick={() => {
                          modalEstadoSolicitud(true, 20);
                        }}
                      >
                        Devolver
                      </button>
                      <button
                        className="btnBlue"
                        onClick={() => {
                          modalEstadoSolicitud(true, 10);
                        }}
                      >
                        Aprobar
                      </button>
                      <button
                        className="btnOutline"
                        onClick={() => {
                          modalEstadoSolicitud(true, 13);
                        }}
                      >
                        Rechazar
                      </button>
                    </div>
                  </>
                )
              )
            ) : (
              <span className="subtitulo-solicitud">
                Atendido por: {solicitudDetalle?.atendidoPorNombre}
              </span>
            )}
            <div className="mt-2 mb-2 d-flex">
              <span className="subtitulo-solicitud ">
                Estado actual: {solicitudDetalle?.estadoNombre}
              </span>

              <button
                type="submit"
                className="btnActualizar invisibleBtn ml-3"
                ref={btnActualizar}
                onClick={
                  ActualizacionParcial == true
                    ? handleSubmit(actualizacionParcial)
                    : handleSubmit(actualizar)
                }
              >
                Actualizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailHeader;
